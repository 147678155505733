import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import { useNavigate } from 'react-router-dom';
// import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
// import { GridToolbar } from "@progress/kendo-react-grid";
import { read, utils } from 'xlsx';
// import XLSX from 'xlsx';
//import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import EditManageZipCodePopup from './Uploadxl';
import EditManageRecyclePopup from './Recycle';
import Popup from "../../Component/Popup/Popup";
import RecycPopup from "../../Component/Popup/RecyclePopup";
import SelectAllPopup from './SelectAllPopup';




const Customers = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [PageNn, setPageNo] = useState(10);

    const pageLimit = PageNn;
    const [customersInfo, setCustomersInfo] = useState("");
    const [Customers, setCustomers] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [customerName, setCustomerName] = useState("");
    // const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    // const [date, setdate] = useState("");
    let onSelectedData = [];

    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");

    // const [, setSelectAll] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isSelect, setIsSelect] = useState(false);

    const [IsRecycle, setIsRecycle] = useState(false);

    //selectall
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedDontCall, setSelectedDontCall] = useState("");
    const [allDontCall, setAllDontCall] = useState("");

    const [flag, setFlag] = useState(false)
    const [select, setSelect] = useState(false);
    const [len, setLen] = useState("")
    const [checkLen, setCheckLen] = useState("")
    const [newSelect, setNewSelect] = useState([]);
    const [selectedPage, setSelectedPage] = useState(false);
    const [page, setPage] = useState("")
    const [, setSelectAll] = useState(false);
    const [Status, setStatus] = useState("")
    const [CustError, setCustError] = useState("");
    const [StatusError, setStatusError] = useState("");
    const [Count, setCount] = useState("");
    //  const [flagToggle, setFflagToggle] = useState(false)
    const [Filterstatus, setFilterstatus] = useState("");
    const [Filterstatus1, setFilterstatus1] = useState("");
    const [FilterAssign, setFilterAssign] = useState("");
    const [StatusID, setStatusID] = useState("");


    const [FilterSelect, setFilterSelect] = useState([]);



    const [PageselectAll, setPageselectAll] = useState(false);
    console.log("PageselectAll", PageselectAll);

    const [CompanyName, setCompanyName] = useState("");
    const [SelectedCompanyName, setSelectedCompanyName] = useState("");
    const [/* operator */, setCompanyid] = useState("");
    const [SelectedStatus, setSelectedStatus] = useState("");

    const [/* status_name */, setstatus_name] = useState("");
    // const [CustomerID, setCustomerID] = useState("");
    console.log(newSelect, "newSelect");
    //console.log("CustomerID",CustomerID);

    // const [ListName, setListName] = useState("");
    // const [SelectedListName, setSelectedListName] = useState("");
    // const [ListId, setListId] = useState("");

    const [unCheckLen, setunCheckLen] = useState("")
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const toggleEditManageZipCodePopup = () => {
        setIsEdit(!isEdit);
    }

    const toggleSelectPopup = () => {
        setIsSelect(!isSelect);
    }

    const toggleEditManageRecyclePopup = () => {
        setIsRecycle(!IsRecycle);
    }

    useEffect(() => {

        if (currentPage === page && selectedPage === 1) {
            setSelectAll(true)
            setSelect(true)
        }
        else if (currentPage === page) {
            setSelect(true)
        }

        else {
            setSelectAll(false)
            setSelect(false)
        }



    }, [currentPage])

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        // console.log("mobileno", value);
        return value;
    }

    useEffect(() => {
        handleCompanyName();
        StatusList();
        OperatorList();

        // handleListName()
    }, [])
    useEffect(() => {
        const dontCallCount = selectedValues.filter(selectedValues => selectedValues.status_name === "Don't Call").length;
        setSelectedDontCall(dontCallCount)
    }, [selectedValues])


    // const handleListName = () => {

    //     API.post("customerlistname/condition").then(response => {
    //         setListName(response.data.data);
    //     });
    // }

    // const handleListId = (e) => {
    //     setListId(e.target.value);
    //     console.log("=========check", e.target.value);
    //     setSelectedListName(
    //         ListName.filter((data) => data.customer_list_id == e.target.value)[0]
    //     );
    //     console.log(
    //         "=========check123",
    //         ListName.filter((data) => data.customer_list_id == e.target.value)[0]
    //     );
    // };

    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {
                setoperatorID(response.data.data);
            }
        });
    }

    const handleBuyerId = (e) => {
        setoperator(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
    };

    const handleCompanyName = () => {

        API.post("companyListview/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const StatusList = () => {
        API.post('callstatuslist/condition',).then((response) => {
            if (response.data.success == true) {
                setStatusID(response.data.data);

            }
        });
    }

    const customerListforxls = () => {

        API.post("customerListforxls/condition").then(response => {
            setCustomers(response.data.data);
        });
    }

    //select allpage
    const customerSelectAll = () => {
        let request = {
            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            data: search,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
        }

        API.post("customerselectall/condition", request).then(response => {
            setFilterSelect("");
            setFilterSelect(response.data?.data.map(data => ({ customer_id: data.customer_id })));
        });
    }



    useEffect(() => {
        setCustomersInfo(customersInfo);

    }, [customersInfo])

    console.log("asperpage", currentPage, PageNn, pageLimit);
    console.log("currentPage:", currentPage);
    console.log("pageLimit:", pageLimit);
    console.log("customersInfo:", customersInfo);
    console.log(/* Selectedoperator */fromdate, todate, PageNn, "Selectedfilter");

    useEffect(() => {
        CustomersListInfo();
        customerListforxls();
        customerSelectAll();
    }, [currentPage, pageLimit])

    const CustomersListInfo = () => {

        let request = {

            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            data: search,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post('customerfilter/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setCount(response.data.data1[0].count);

                // setstatus_name(response.data?.data.status_name);
                setTotalRecords(response.data.data1[0].count);
                setAllDontCall(response.data.data2[0].count)
                /* document.getElementById("page").value = "10"; */
                // setflag(false);
                /*  setPageNo(10); */
                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

                // console.log("res", response.data.data);
                //  setCustomers(response.data.data);
            }
            setLoading(false);
            // setCurrentPage(1);
        });
    }


    //afterclear calling list function
    const CustomersListInfoClear = () => {

        let request = {

            customer_name: "",
            company_id: "",
            customer_list_id: "",
            email: "",
            phone_no: "",
            from_date: "",
            to_date: "",
            status: "",
            data: "",
            Assignstatus: "",
            customer_status: "",
            rowsPerPage: "10",
            pageNumber: "1",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post('customerfilter/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);
                setAllDontCall(response.data.data2[0].count)

                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

            }
            setLoading(false);

        });
        API.post('customerList/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);

                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

            }
            setLoading(false);

        });
    }

    console.log(currentPage, "currentPage");


    // const exportExport = () => {
    //     if (_export.current !== null) {
    //         _export.current.save();
    //     }
    // };

    // const [data, setData] = useState(null);
    // console.log("data", data);
    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //         const binaryData = event.target.result;

    //         const workbook = read(binaryData, { type: 'binary' });

    //         const worksheet = workbook.Sheets['Sheet1'];

    //         const sheetData = utils.sheet_to_json(worksheet, { header: 1 });


    //         const columnNames = ['first_name', 'last_name', 'email', '', "Phone"];

    //         const namedArray = sheetData.map((innerArray) => {
    //             return innerArray.reduce((acc, curr, index) => {
    //                 acc[columnNames[index]] = curr;
    //                 return acc;
    //             }, {});
    //         });
    //         console.log("namedArray sheetData", namedArray);

    //         // const namedArray2 = sheetData.map((innerArray) => {
    //         //     return innerArray.map((value) => {
    //         //       if (typeof value === 'string' && value.match(/^\(\d{3}\) \d{3}-\d{4}$/)) {
    //         //         // Phone number is in US format, so normalize it
    //         //         return value.replace(/\D/g, '');
    //         //       } else {
    //         //         // Value is not a phone number, so return as-is
    //         //         return value;
    //         //       }
    //         //     });
    //         //   });
    //         const array = namedArray;
    //         const normalizedArray = array.map(obj => {
    //             const normalizedPhoneNumber = obj.Phone.replace(/\D+/g, '');
    //             return {
    //                 ...obj,
    //                 Phone: normalizedPhoneNumber
    //             };
    //         });

    //         console.log("namedArray", normalizedArray);

    //         setData(normalizedArray);
    //     };



    //     reader.readAsBinaryString(file);
    // };
    const handleCompanyId = (e) => {
        setCompanyid(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedCompanyName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
    };



    useEffect(() => {
        if (!flag) {
            setSelect(false)
            console.log("useeffect first if calling");
        } else {
            if ((len !== unCheckLen) && (len !== checkLen)) {
                console.log("useeffect status", len !== unCheckLen);
                setSelect(false)
            } else if (checkLen === len) {
                setSelect(true)
                // setFlag(true)
                console.log("useeffect else if calling");

            } else {
                setSelect(false)
                console.log("useeffect else calling");

            }

        }

    }, [unCheckLen, checkLen])
    console.log("checklen", len, "uncheck", unCheckLen, checkLen, "checkLen");

    //inactive excel
    let previousFile = null;
    const ImportInactive = ($event) => {
        console.log("ImportInactive inside");
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            console.log(file, 'sheets1');
            if (file !== previousFile) {

                previousFile = file;
                $event.target.value = null;
            }
            const reader = new FileReader();
            console.log(reader, 'sheets2');
            reader.onload = (event) => {
                const binaryData = event.target.result;
                const workbook = read(binaryData, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const sheetData = utils.sheet_to_json(worksheet, { header: 1 }); // Include header row
                console.log(sheetData, 'sheetData');

                const columnNames = ['first_name', 'last_name', 'vehicle', 'version', 'Phone', 'source', 'email', 'color']; // Header row
                // sheetData.shift(); // Remove header row from data

                const validNumbers = sheetData
                    .filter(innerArray => innerArray[4] && String(innerArray[4]).match(/^\d{3}-\d{3}-\d{4}$/) && !/[a-zA-Z]/.test(innerArray[4]))
                    .map(innerArray => {
                        const normalizedPhoneNumber = String(innerArray[4]).replace(/[-]/g, '');
                        console.log("namedArray2", normalizedPhoneNumber);
                        return {
                            first_name: innerArray[0],
                            last_name: innerArray[1],
                            vehicle: innerArray[2],
                            version: innerArray[3],
                            source: innerArray[5],
                            email: innerArray[6],
                            color: innerArray[7],
                            [columnNames[4]]: normalizedPhoneNumber
                        };
                    });
                console.log("namedArray3", validNumbers);

                let request = {
                    customerlist: validNumbers,
                }
                console.log("Modified rows", validNumbers);
                if (validNumbers.length == 0) {
                    togglePopup();

                    setPopupTitle("Upload Error");
                    setPopupMsg("No valid phone numbers found in the uploaded file.");
                    setPopupType("error");
                    setPopupActionType("close");
                    setPopupActionValue("ok");



                }
                else {

                    API.post('customerinactive/update', request).then((response) => {
                        console.log("response", response);
                        if (response.data.success == true) {
                            togglePopup();
                            setPopupTitle("Customer");
                            setPopupMsg("Customer Inactive Successfully");
                            setPopupType("success");
                            setPopupActionType("close");
                            setPopupActionValue("ok");
                            setLoading(true)
                            CustomersListInfo();
                        } else {
                            togglePopup();
                            setPopupTitle("Customer");
                            setPopupMsg(response.data.error.err);
                            setPopupType("error");
                            setPopupActionType("close");
                            setPopupActionValue("close");
                        }
                        $event.target.value = null;
                    });
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }





    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const CustomerSearch = () => {
        customerSelectAll();
        let request = {
            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            data: search,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post("customerfilter/condition", request).then(response => {

            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
            setAllDontCall(response.data.data2[0].count)

            setCount(response.data.data1[0].count);

            setTotalRecords(response.data.data1[0].count);
            // setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                CustomerSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            CustomersListInfo();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            CustomerSearch();
        }
    };
    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const customerFilter = () => {

        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }

        setLoading(true)
        customerSelectAll();
        let request = {
            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            data: search,
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post("customerfilter/condition", request).then(response => {
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));

            setCount(response.data.data1[0].count);
            setAllDontCall(response.data.data2[0].count)


            setTotalRecords(response.data.data1[0].count);
            setLoading(false)

            /*   document.getElementById("page").value = "10";
              setCurrentPage(1);
              setPageNo(10); */
        });
    }


    //toggle service
    const editable = (data, id) => {
        console.log("data", data, "value====", "id", id);
        let statusChecked = document.getElementById(id)
        console.log("statusChecked", statusChecked);
        if (data.status_name === "Don't Call") {
            console.log("inside if");
            togglePopup();
            setPopupTitle("Edit Customer");
            if (data.status === "Active") {
                setPopupMsg("Are You Want To Customer Inactive ");
            }
            else {
                setPopupMsg("Are You Want To Customer Active ");
            }
            setPopupType("success");
            setPopupActionValue("ok");
            setPopupActionType("close");
            // setPopupActionPath("/customers");
        }


        let request = {
            customer_id: data.customer_id,
            status: !statusChecked.checked ? "Inactive" : "Active"
        }
        console.log(request, "request");
        API.post("customervoidbyid/update", request).then(response => {

            if (response.data.success) {
                /* document.getElementById("page").value = "10";
                setCurrentPage(1); */
                setLoading(true);
                CustomersListInfo()

            }
        });

    }

    const handleSelectField = (item, id, flag, crntPage) => {
        setPageselectAll(false);
        console.log("newSelect", newSelect);

        setSelect(!select)
        setFlag(flag)
        setStatusError("")
        onSelectedData.map((data) => { data.customer_id })
        // console.log("checkitem", id);
        const checkbox = document.getElementById(id)
        const filter = newSelect.filter(data => data.crntPage === crntPage);
        setNewSelect(filter.length ? newSelect.filter(item => item.crntPage !== crntPage) : [...newSelect, { crntPage }]);

        if (checkbox != null) {

            if (checkbox.checked === true) {
                console.log("if calling");
                const checkPage = checkbox.checked === true ? 1 : ""
                const checkPage1 = currentPage
                setSelectedPage(checkPage)
                setPage(checkPage1)
                console.log("checkitem checkPage", onSelectedData.map((data) => { return { "customer_id": data.customer_id, "status_name": data.status_name } }));
                setSelectAll(true)
                setSelect(true)

                const selValues = [...selectedValues, ...onSelectedData.map((data) => { return { "customer_id": data.customer_id, "status_name": data.status_name } })]
                const filteredArr = selValues.reduce((acc, current) => {
                    const x = acc.find(item => item.customer_id === current.customer_id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                setSelectedValues(filteredArr)

                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.customer_id === onSelectedData[i].customer_id);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = true;
                    sorting[findIndex].currentPage = crntPage;
                    setCustomersInfo(sorting);
                }

                // customersInfo.sort((a, b) => b.customer_id - a.customer_id) 
                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: true } }))
                // data.customer_id
                //setflag(true);
            }
            else {
                console.log("else calling");
                const tem = selectedValues;
                //console.log("elseif calling");
                setSelectAll(false)
                for (let i = 0; i < onSelectedData.length; i++) {
                    for (let j = 0; j < tem.length; j++) {
                        if (tem[j].customer_id === onSelectedData[i].customer_id) {
                            tem.splice(j, 1);
                        }

                    }
                }

                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: false } }))
                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.currentPage === onSelectedData[i].currentPage);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = false;
                    sorting[findIndex].currentPage = '';
                    setCustomersInfo(sorting);
                }
                // setflag(true);
            }
        }
    };


    const handleSelectChangeField = (items, crntPage) => {
        setStatusError("");
        setPageselectAll(false);
        console.log("items", items);
        setstatus_name(items)
        console.log("check the current pages", onSelectedData);

        const uncheckstatus = selectedValues.filter(data => data.customer_id !== items.customer_id);
        console.log("customersInfolenght", uncheckstatus.length, customersInfo.length);
        setunCheckLen(uncheckstatus.length)

        if (checkLen !== onSelectedData.length) {
            document.getElementById("SelectAll").checked = false;
            setSelect(false)

            console.log("single uncheck", uncheckstatus.length, onSelectedData.length, "checkLen", checkLen);
        }

        const checkval = selectedValues.filter((data) => data.customer_id === items.customer_id);

        if (checkval.length > 0) {

            console.log("if check calling");
            const remove = customersInfo.filter(data => data.customer_id === items.customer_id);
            setNewSelect(newSelect.filter(item => item.crntPage !== remove[0].currentPage));

            const allList = customersInfo;
            console.log("selectedValues", selectedValues);
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            allList[findIndex].isChecked = false;
            allList[findIndex].currentPage = '';
            console.log("findIndex if", findIndex);
            setCustomersInfo(allList);
            setSelectedValues(selectedValues.filter(data => data.customer_id !== items.customer_id));
            const value = selectedValues.filter(data => data.customer_id !== items.customer_id).length;
            console.log("value", value);
            setCheckLen(value.length);
            console.log("single selectedValues if", selectedValues.filter(data => data.customer_id !== items.customer_id).length);
            if (value === 0) {
                setStatusError("");
            }

        }

        else {

            console.log("else check calling");
            console.log("selectedValues", selectedValues);
            const allList = customersInfo;
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            // console.log("findIndex else",findIndex);
            allList[findIndex].isChecked = true;
            allList[findIndex].currentPage = crntPage;

            setCustomersInfo(allList);
            setSelectedValues([...selectedValues, { "customer_id": items.customer_id, "status_name": items.status_name }])
            const value = [...selectedValues, { "customer_id": items.customer_id, "status_name": items.status_name }]
            setCheckLen(value.length);
            console.log("single selectedValues last", value.length);
            // setStatusError("")
            const sellectAllElement = onSelectedData.filter(data => data.isChecked === true)
            if (sellectAllElement.length === onSelectedData.length && crntPage === currentPage) {
                setNewSelect([...newSelect, { crntPage }]);
            }

            console.log("element", sellectAllElement.length === onSelectedData.length && crntPage === currentPage);

        }


    };


    const handleSearch = () => {
        setFilterstatus1(Filterstatus)
        customerFilter();
    }

    const handleclear = () => {

        setCustomerName("");
        setPhoneNo("");
        setCompanyName("");
        setStatusID("");
        setfromdate("");
        settodate("");
        setFromDateError("");
        setCustError("");
        setStatusError("");
        setStatus("")
        setSelectedCompanyName("")
        setSelectedStatus("")
        setFilterAssign("")
        setFilterstatus("")
        setFilterstatus1("")
        setoperatorID("");
        setSelectedCompanyName("");
        setSelectedoperator("");
        setSelectedStatus("");
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
        const Filterstatus = document.getElementById("Filterstatus");
        Filterstatus.selectedIndex = 0;

        setSelectedValues("");
        setLoading(true);
        handleCompanyName();
        CustomersListInfoClear();
        StatusList();
        OperatorList();

        // setCurrentPage(1);
        //document.getElementById("page").value = "10";

        // document.getElementById("userName").value = "Select userName";
        setSelectedValues([])

        setNewSelect([]);
        for (let i = 0; i < customersInfo.length; i++) {
            const sorting = customersInfo;
            sorting[i].isChecked = false;
            sorting[i].currentPage = '';
            setCustomersInfo(sorting);
        }
    }
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortByID = () => {
        //    console.log("sortById start");
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.customer_id - userB.customer_id;
            }
            return userB.customer_id - userA.customer_id;
        });
        // usersCopy.sort((userA, userB) =>
        // userA.customer_id > userB.customer_id ? (userB.customer_id - userA.customer_id) : (userA.customer_id - userB.customer_id))
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });

    };

    const sortByCustomerName = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.customername || '';
            userB = userB.customername || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "customername", reversed: !sorted.reversed });

    };



    // const sortByEmail = () => {
    //     const usersCopy = [...customersInfo];
    //     usersCopy.sort((userA, userB) => {
    //         userA = userA.email || '';
    //         userB = userB.email || '';
    //         if (sorted.reversed) {
    //             console.log("sortById if");
    //             return userB.localeCompare(userA);
    //         }

    //         return userA.localeCompare(userB);

    //     });
    //     setCustomersInfo(usersCopy);
    //     setSorted({ sorted: "email", reversed: !sorted.reversed });
    // };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userA.Phone - userB.Phone;
            }
            return userB.Phone - userA.Phone;
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "phone", reversed: !sorted.reversed });
    };

    const sortByComment = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.customername || '';
            userB = userB.customername || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "CommentsByAdmin", reversed: !sorted.reversed });
    };

    const sortByCompanyName = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.company_name || '';
            userB = userB.company_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "company_name", reversed: !sorted.reversed });

    };

    const sortByListName = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.list_name || '';
            userB = userB.list_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });

    };
    const sortByOperatorName = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.operatorname || '';
            userB = userB.operatorname || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "operatorname", reversed: !sorted.reversed });

    };

    const sortByDatetime = () => {
        console.log("sortById start");
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.createdtime.localeCompare(userA.createdtime);
            }

            return userA.createdtime.localeCompare(userB.createdtime);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "createdtime", reversed: !sorted.reversed });
    };

    const handleDropdownChange = (event) => {
        console.log("event123", event);
        setStatus(event.target.value);
        //console.log("ifcalling1",customersInfo.status_name);
        // if (event.target.value === "Active") {
        //   window.alert("Option selected: Active");
        // }
        // if (status_name.status_name === "Don't Call") {
        //     // console.log("ifcalling",customersInfo.status_name);
        //     togglePopup();
        //     setPopupTitle("Edit Customer");
        //     setPopupMsg("Are You Want To Customer Activesssssssss ");
        //     setPopupType("success");

        //     setPopupActionValue("ok");
        //     setPopupActionType("close");
        //     // setPopupActionPath("/customers");
        // }
        // else {
        //     // setEmailError(response.data.error.err);
        //     // togglePopup();
        //     // setPopupTitle("Edit Customer");
        //     // setPopupMsg(response.data.error.err);
        //     // setPopupType("error");
        //     // setPopupActionType("close");
        //     // setPopupActionValue("close");
        // }
    };

    function formatTime(value) {
        let local = ""
        let offset = ""
        // let utc1 = ""
        console.log(value, "yyyy1");
        local = new Date(value);

        //var local = new Date("2023-05-22 08:21:11");
        offset = local.getTimezoneOffset();
        value = new Date(local.getTime() - offset * 60000);
        value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }

    const ZipcodePopup = () => {
        toggleEditManageZipCodePopup()
    }

    const SelectCheckBoxPopup = () => {
        setCustError("");
        setStatusError("");
        if (PageselectAll === false) {
            if (selectedValues.length === 0) {
                setCustError("Customer Name is required");
                return;
            }
        }
        if (!Status) {
            setStatusError(" Status is required");
            return;
        }
        toggleSelectPopup();
    }


    const RecyclePopup = () => {

        toggleEditManageRecyclePopup()
    }

    const reCallFunction = () => {
        CustomersListInfo();
    }

    const reCallFunction1 = () => {
        document.getElementById("page").value = "10";
        setCurrentPage(1);
        setLoading(true);
        setPageselectAll(false);
        setStatus("")
        setStatusError("");
        setSelectedValues([])
        CustomersListInfo();
        setNewSelect([]);
        for (let i = 0; i < customersInfo.length; i++) {
            const sorting = customersInfo;
            sorting[i].isChecked = false;
            sorting[i].currentPage = '';
            setCustomersInfo(sorting);
        }
    }

    // const inputPropsdate = {
    //     placeholder: "DD/MM/YYYY",
    //     value: date
    // };

    // const yesterday = moment().subtract(1, 'day');
    // const disablePastDate = current => {
    //     return current.isAfter(yesterday);
    // };
    // const registrationDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         setdate(event.format("YYYY-MM-DD"));
    //     }
    // }
    const handleStatusId = (e) => {
        setStatus(e.target.value);
        console.log("=========check status", e.target.value);
        setSelectedStatus(
            StatusID.filter((data) => data.call_status_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            StatusID.filter((data) => data.call_status_id == e.target.value)[0]
        );
    };

    const handleAllPageSelectField = (PageselectAll) => {
        setStatusError("");

        // Create a copy of the customersInfo array with updated isChecked values
        const updatedCustomersInfo = customersInfo.map((customer) => ({
            ...customer,
            isChecked: false, // Set isChecked to false when "Select All Pages" is checked
        }));
        setSelect(false);
        setFlag("");
        setStatusError("");
        setNewSelect([]);
        setSelectedPage("");
        setPage("");
        setSelectedValues([]);
        setLen(0);
        // Update the state with the modified array
        setCustomersInfo(updatedCustomersInfo);

        // Update the state for "Select All Pages" checkbox
        setPageselectAll(!PageselectAll);
    };


    const marginTop = selectedValues.length > 0 || PageselectAll === true ? '50px' : '10px';
    console.log(marginTop);

    //const  marginTop1 =  PageselectAll.length>0 ? '50px': '10px';


    //ImportDontcall excel
    let previousDontcallFile = null;
    const ImportDontCall = ($events) => {
        console.log("handleimport inside");
        const files = $events.target.files;
        if (files.length) {
            const file = files[0];
            console.log(file, 'sheets1');
            if (file !== previousDontcallFile) {

                previousDontcallFile = file;
                $events.target.value = null;
            }
            const reader = new FileReader();
            console.log(reader, 'sheets2');
            reader.onload = (event) => {
                const binaryData = event.target.result;
                const workbook = read(binaryData, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const sheetData = utils.sheet_to_json(worksheet, { header: 1 }); // Include header row
                console.log(sheetData, 'sheetData');

                const columnNames = ['first_name', 'last_name', 'vehicle', 'version', 'Phone', 'source', 'email', 'color']; // Header row
                // sheetData.shift(); // Remove header row from data

                const validNumbers = sheetData
                    .filter(innerArray => innerArray[4] && String(innerArray[4]).match(/^\d{3}-\d{3}-\d{4}$/) && !/[a-zA-Z]/.test(innerArray[4]))
                    .map(innerArray => {
                        const normalizedPhoneNumber = String(innerArray[4]).replace(/[-]/g, '');
                        console.log("namedArray2", normalizedPhoneNumber);
                        return {
                            first_name: innerArray[0],
                            last_name: innerArray[1],
                            vehicle: innerArray[2],
                            version: innerArray[3],
                            source: innerArray[5],
                            email: innerArray[6],
                            color: innerArray[7],
                            [columnNames[4]]: normalizedPhoneNumber
                        };
                    });
                console.log("namedArray3", validNumbers);

                let request = {
                    customerlist: validNumbers,
                }
                console.log("Modified rows", validNumbers);
                if (validNumbers.length == 0) {
                    togglePopup();
                    setPopupTitle("Upload Error");
                    setPopupMsg("No valid phone numbers found in the uploaded file.");
                    setPopupType("error");
                    setPopupActionType("close");
                    setPopupActionValue("ok");



                }
                else {
                    API.post('customerdontcalls/update', request).then((response) => {
                        console.log("response", response);
                        setLoading(false)
                        if (response.data.success == true) {
                            togglePopup();
                            setPopupTitle("Customer");
                            setPopupMsg("Customer Dontcall Successfully");
                            setPopupType("success");
                            setPopupActionType("close");
                            setPopupActionValue("ok");
                            setLoading(true)
                            CustomersListInfo();
                        } else {
                            togglePopup();
                            setPopupTitle("Customer Dontcall");
                            setPopupMsg(response.data.error.err);
                            setPopupType("error");
                            setPopupActionType("close");
                            setPopupActionValue("close");
                        }
                        $events.target.value = null;
                    });
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }







    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Customers</h5>
                    <div className="row mb-3">

                        <div className='d-flex justify-content-end pes-link xl-buttons'>
                            <div className="custom-file me-2">
                                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={ImportInactive}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                                <label className="custom-file-label" htmlFor="inputGroupFile"><i className="fa-solid fa-upload"></i>INACTIVE</label>
                            </div>
                            {/* <GridToolbar>
                                <div onClick={exportExport} className="download-xl me-2"><i className="fa-solid fa-download me-1"></i>Download XL</div>
                                <ExcelExport ref={_export}>

                                    <ExcelExportColumn
                                        field="first_name"
                                        locked={true}
                                        width={150}
                                    />
                                    <ExcelExportColumn
                                        field="last_name"
                                        locked={true}
                                        width={150}
                                    />
                                    <ExcelExportColumn
                                        field="email"
                                        locked={true}
                                        width={200}
                                    />
                                    <ExcelExportColumn
                                        field="Phone"
                                        locked={true}
                                        width={100}
                                    />
                                    <ExcelExportColumn
                                        field="CommentsByAdmin"
                                        locked={true}
                                        width={150}
                                    />
                                </ExcelExport>
                            </GridToolbar> */}
                            <CSVLink data={Customers} filename="customer" className="download-xl me-2">Download XL</CSVLink>
                            {/* <input type="file" accept=".xlsx" onChange={handleFileUpload} />
                            <label className="custom-file-label" htmlFor="inputGroupFiles"><i className="fa-solid fa-cloud-arrow-up me-1"></i>Upload XL1</label> */}
                            {/* <div className="custom-file me-2">

                                <input type="file" name="file" className="custom-file-input" id="inputGroupFiles" required onChange={handleImport}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                                <label className="custom-file-label" htmlFor="inputGroupFiles"><i className="fa-solid fa-cloud-arrow-up me-1"></i>Upload XL</label>
                            </div> */}
                            <div className="custom-file me-2" onClick={() => { ZipcodePopup() }}><i className="fa-solid fa-cloud-arrow-up me-1"></i>Upload XL</div>

                            <div className="custom-file me-2">
                                <input type="file" name="file" className="custom-file-input" id="inputDontCall" required onChange={ImportDontCall}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                                <label className="custom-file-label" htmlFor="inputDontCall"><i className="fa-solid fa-phone-slash"></i>Don&rsquo;t call</label>
                            </div>

                        </div>





                        <div className="common-heading-sec cus">
                            <div className="col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleCompanyId}
                                >
                                    <option style={{ display: "none" }}>Company Name</option>
                                    {CompanyName?.length > 0 && (
                                        <>
                                            {CompanyName.map((CompanyNameID) => (
                                                <option
                                                    key={CompanyNameID.company_id}
                                                    value={CompanyNameID.company_id}
                                                >
                                                    {CompanyNameID.company_name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className=" col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleStatusId}
                                    defaultValue={Status}
                                >
                                    <option style={{ display: "none" }} >Call Status</option>
                                    {StatusID?.length > 0 && (
                                        <>
                                            {StatusID.map((StatusID) => (
                                                <option
                                                    key={StatusID.call_status_id}
                                                    value={StatusID.call_status_id}
                                                >
                                                    {StatusID.status_name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-2 me-2">
                                {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                <select className="form-select" id="status" onChange={(e) => setFilterAssign(e.target.value)}>
                                    <option selected style={{ display: "none" }}  >Assign Status</option>
                                    <option value="Assign">Assign</option>
                                    <option value="Not Assign">Not Assign</option>
                                </select>
                            </div>
                            <div className="col-lg-2 me-2">
                                {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                <select className="form-select" id="Filterstatus" onChange={(e) => setFilterstatus(e.target.value)}>
                                    <option selected style={{ display: "none" }}  >Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                    <option value="Dontcall">Don&rsquo;t call</option>
                                </select>
                            </div>
                            <div className=" col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleBuyerId}
                                >
                                    <option style={{ display: "none" }}>Operator Name</option>
                                    {operatorID?.length > 0 && (
                                        <>
                                            {operatorID.map((operatorID) => (
                                                <option
                                                    key={operatorID.operator_id}
                                                    value={operatorID.operator_id}
                                                >
                                                    {operatorID.operatorname}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>



                        </div>





                    </div>
                    <div className='row mb-2'>
                        {/* <div className=" col-lg-2 phone">
                            <input type="text" className="form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </div> */}
                        <div className=" col-lg-2 me-2">
                            <input type="text" className="form-control" value={customerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                        </div>
                        <div className=" col-lg-2 phone ">
                            <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                        </div>
                        <div className=" col-lg-2 datePickerBlock ">
                            {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                            <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                name="Date" onChange={fromDate} closeOnSelect={true}
                                id="meeting_date" />
                        </div>
                        <div className="col-lg-2 datePickerBlock">
                            <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                id="meeting_date" />
                            <div className="error-msgs">
                                <p className="form-input-error" >{fromDateError}</p>
                            </div>
                        </div>

                        {/* <div className="col-lg-2 me-2">
                                            <label htmlFor="customerName" className="form-label">List Name </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div> */}
                        <div className="col-lg-2 filterfind">
                            <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass "></i>Find</div>
                            <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                        </div>
                    </div>

                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>

                            <div className='PageselectAll'>
                                <span> Select All Pages</span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={PageselectAll}

                                    onChange={() => { handleAllPageSelectField(PageselectAll) }} />


                            </div>

                        </span>

                        <div className='col-xl-5 custaddrecycle'>
                            <div className="search-overall me-2">
                                <input type="search" className="form-control" placeholder="Name or Id or Phone#" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                            </div>
                            <div className='add-section'>

                                <div className="add-btn" onClick={() => navigate("/customersAdd")}><i className="fa-solid fa-plus"></i>Add</div>
                                {/* {selectedValues.length > 0 ?
                                    <div className="add-btn" onClick={() => getSelectedReport()} >Save</div> :
                                    <> </>
                                } */}

                                <div className="add-btn" onClick={() => { RecyclePopup() }} >Recycle</div>
                                <div className="add-btn" onClick={() => navigate("/reassigncustomers")}>Reassign</div>

                            </div>
                        </div>

                    </div>

                    {selectedValues.length > 0 || PageselectAll ?
                        <>
                            <div className="col-lg-2 activestatus">

                                <select className="form-select me-1" id="operatorName" value={Status} onChange={handleDropdownChange}>
                                    <option style={{ display: "none" }} >Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>

                                </select>
                                <p className="form-input-errors">{StatusError}</p>
                                <p className="form-input-custerror">{CustError}</p>
                                <div className="add-btn "/*  disable= {selectedValues.length>0 ?false:true} */ onClick={() => SelectCheckBoxPopup()}  >Submit</div>
                            </div>

                        </> :
                        <> </>
                    }

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive" style={{ marginTop }}>
                                <table className="table bg-white" >
                                    <thead>
                                        <tr>
                                            <th onClick={sortByID}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCustomerName}>Customer Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            {/* <th onClick={sortByEmail}>Email Id<i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByComment}>Comments<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDatetime}>Date & Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            {/* <th>Status</th> */}
                                            <th onClick={sortByCompanyName}>Company Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Status</th>
                                            {/* <th>Inactive <input  className="form-check-input"  type="checkbox"  id="SelectAll" onClick={() => { handleSelectField(customersInfo, "SelectAll") }}/></th> */}

                                            <th>Call Status</th>
                                            <th className='text-center action-right-fix-list2'>SelectAll <input className="form-check-input" type="checkbox"
                                                checked={newSelect.find((newSelect) => {
                                                    return newSelect.crntPage === currentPage
                                                }) ? true : false} id='SelectAll'
                                                onClick={() => { handleSelectField(customersInfo, "SelectAll", true, currentPage) }} /></th>

                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo.length > 0 ? (
                                            customersInfo.sort((a, b) => b.customersInfo - a.customersInfo)
                                                // .slice((currentPage) * pageLimit, currentPage * pageLimit)
                                                .map((customersInfo, index) => {
                                                    onSelectedData = [...onSelectedData, customersInfo]
                                                    return (<tr key={index}>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customer_id}</a></td>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customername}</a></td>
                                                        {/* <td>{customersInfo.email}</td> */}
                                                        {/* {customersInfo.email === null || customersInfo.email === "null" ? <td></td> : <td>{customersInfo.email}</td>} */}
                                                        {/* <td></td> */}
                                                        {customersInfo.Phone === null || customersInfo.Phone === "null" ? <td></td> : <td>{formatMobileNO(customersInfo.Phone)}</td>}
                                                        {/* <td>{customersInfo.CommentsByAdmin}</td> */}
                                                        {customersInfo.CommentsByAdmin === null || customersInfo.CommentsByAdmin === "null" ? <td></td> : <td>{customersInfo.CommentsByAdmin}</td>}
                                                        <td>{formatTime(customersInfo.createdtimes)}</td>

                                                        {/* <td>{customersInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td> */}
                                                        <td>{customersInfo.company_name}</td>
                                                        <td>{customersInfo.list_name}</td>
                                                        <td>{customersInfo.operatorname}</td>

                                                        <td>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" id={`touggle${index}`} checked={customersInfo.status === "Active"} onClick={() => editable(customersInfo, `touggle${index}`)} />
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                        <div>

                                                            <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} disabled={customersInfo.status != "Active"} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, `selectId${index}`); }} />
                                                        </div>
                                                    </td> */}




                                                        {customersInfo.status_name === null || customersInfo.status_name === "null" ?
                                                            <td></td> : <td>{customersInfo.status_name}</td>}

                                                        <td className='text-center action-right-fix-list2'> 
                                                            <div>

                                                                <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, currentPage); }} />
                                                            </div>
                                                        </td>





                                                        <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate("/customerEdit", ls.set("AdminId", customersInfo.customer_id))}>Edit</a>
                                                            <a className="edit" onClick={() => navigate("/CustomerView", ls.set("AdminId", customersInfo.customer_id))}>view</a></td>
                                                    </tr>)
                                                }
                                                )) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={(page) => setCurrentPage(page)}
                                />
                            </ul>
                        </>
                    }



                </div>
            </div>
            {isEdit && <Popup
                isClose={false}
                content={<>
                    <EditManageZipCodePopup toggle={toggleEditManageZipCodePopup} reCallFunction={reCallFunction} />
                </>}
                handleClose={toggleEditManageZipCodePopup}
            />}

            {isSelect && <Popup
                isClose={false}
                content={<>
                    <SelectAllPopup toggle={toggleSelectPopup} reCallFunction={reCallFunction1} selectedValues={selectedValues} PageselectAll={PageselectAll} Status={Status} Filterstatus={Filterstatus1} FilterSelect={FilterSelect} selectedDontCall={PageselectAll == "false" || PageselectAll == "" ? selectedDontCall : allDontCall} />
                </>}
                handleClose={toggleSelectPopup}
            />}

            {IsRecycle && <RecycPopup
                isClose={false}
                content={<>
                    <EditManageRecyclePopup toggle={toggleEditManageRecyclePopup} reCallFunction={reCallFunction} />
                </>}
                handleClose={toggleEditManageRecyclePopup}
            />}
            {isOpenPopup && (
                < CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                />
            )}
        </div>
    );
};

export default Customers;