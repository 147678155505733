import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import PhoneInputs from 'react-phone-input-2'

const CustomerAdd = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [pNumber, setPNumber] = useState("");
    const [comment, setComment] = useState("");
    const [Vehicle, setVehicle] = useState("");
    const [Stock, setStock] = useState("");
    const [Color, setColor] = useState("");
    const [Source, setSource] = useState("");
    const [status, setStatus] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    // const [emailError, setEmailError] = useState("");
    const [pNumberError, setPNumberError] = useState("");
    // const [commentError, setCommentError] = useState("");
     const [StatusError, setStatusError] = useState("");

     const [CompanyName, setCompanyName] = useState("");
     const [CompanyId, setCompanyId] = useState("");
     const [CompanyNameError, setCompanyNameError] = useState("");
     const [selectedCustomerName, setSelectedCustomerName] = useState("");

     const [OperatorName, setOperatorName] = useState("");
    const [SelectedOperatorName, setSelectedOperatorName] = useState("");
    const [/* OperatorId */, setOperatorId] = useState("");

     const [ListName, setListName] = useState("");
     const [/* ListId */, setListId] = useState("");
    //  const [ListNameError, setListNameError] = useState("");
     const [SelectedListName, setSelectedListName] = useState("");
     
     const [submit,setSubmit] = useState(false);
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    useEffect(() => {
        handleCompanyName();
       
    }, [])

    const handleCompanyName = () => {

        API.post("mapcompanyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const handleCompany = (e) => {
        setCompanyId(e.target.value);
        const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        setSelectedCustomerName(selectedCustomerDetails);
        handleOperatorName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
    }

    const handleOperatorName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }

        API.post("operatorbycompanyid/condition", request).then(response => {
            setOperatorName(response.data.data);
        });
    }

    const handleOperatorId = (e) => {
        setOperatorId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedOperatorName(
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        handleListName(OperatorName.filter((data) => data.operator_id == e.target.value)[0]);
    };

    const handleListName = (data) => {
        let request = {
            operator_id: !data ? "" : data.operator_id,

        }

        API.post("customerlistassgin/condition", request).then(response => {
            setListName(response.data.data);
        });
    }

    const handleListId = (e) => {
        setListId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedListName(
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    };

    const handleAdd = () => {
        setFirstNameError("");
        setLastNameError("");
        //setEmailError("");
        setPNumberError("");
       // setCommentError("");
        // setStatusError("");
        setCompanyNameError("");
       // setListNameError("");

        if (!firstName) {
            setFirstNameError("First Name is required");
            return;
        }
        if (!lastName) {
            setLastNameError("Last Name is required");
            return;
        }
        // if (!email) {
        //     setEmailError("Email is required");
        //     return;
        // }
        // else if (email && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email)) {
        //     setEmailError('Must match the Username format');
        //     return;
        // }
        if (!pNumber) {
            setPNumberError("Phone Number is required");
            return;
        }
        else if (pNumber.length < 10) {
            setPNumberError("Phone Number must have 10 digits");
            return;
        }
        else if (pNumber.length > 10) {
            setPNumberError("Phone Number accept only 10 digits");
            return;
        }
        // if (!comment) {
        //     setCommentError("Comment is required");
        //     return;
        // }
          if (!status) {
              setStatusError("Status is required");
              return;
          }
          if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }
        // if (!ListId) {
        //     setListNameError("List Name is required");
        //     return;
        // }
        let request = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            Phone: pNumber,
            status: status === "" ? "Active":status,
            CommentsByAdmin: comment,
            vehicle: Vehicle,
            stock:Stock,
            version:"",
            color:Color,
            source:Source,
            active: status==="Active" ? 1 : 4,
            company_id: selectedCustomerName.company_id,
            customer_list_id: /* !SelectedListName ? "" : */ SelectedListName.customer_list_id,
            operator_id: !SelectedOperatorName ? "" : SelectedOperatorName.operator_id,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        };
        console.log("addreq",request)

        setSubmit(true);
        API.post('customerAdd/add ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Add New Customer");
                setPopupMsg("Customer Created Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/customers");
            }
            else {
                setPNumberError(response.data.error.err);
                togglePopup();
                setPopupTitle("Add New Customer");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                    <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/customers')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">Add New Customer</h5>
                        </div>

                        <div className="rounded table-responsive">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add New Customer</h5>
                            </div> */}
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-4  ">
                                            <label htmlFor="first-name" className="col-form-label">First Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="first-name" onChange={(e) => setFirstName(e.target.value)} />
                                            <p className="form-input-error">{firstNameError}</p>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="last-name" className="col-form-label">Last Name <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="last-name" onChange={(e) => setLastName(e.target.value)} />
                                            <p className="form-input-error">{lastNameError}</p>
                                        </div>

                                        <div className="col-lg-4 ">
                                            <label htmlFor="email" className="col-form-label">Email </label>
                                            <input type="text" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} />
                                            {/* <p className="form-input-error">{emailError}</p> */}
                                        </div>

                                        <div className="col-lg-4 ">
                                          
                                            {/* <label htmlFor="phone-number" className="col-form-label">Phone Number <span className='mandatory ms-1'>*</span></label>
                                            <input type="text" className="form-control" id="phone-number" onChange={(e) => setPNumber(e.target.value)} /> */}
                                             <label htmlFor="phone-number" className="col-form-label required"> Phone</label>
                                            <PhoneInputs
                                                class="form-control "
                                                country={'us'}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                onlyCountries={['us']}
                                                onChange={(e) => setPNumber(e)}
                                                placeholder=""
                                            ></PhoneInputs> 
                                            <p className="form-input-error">{pNumberError}</p>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="comment" className="col-form-label">Comments</label>
                                            <input type="text" className="form-control" id="comment" onChange={(e) => setComment(e.target.value)} />
                                            {/* <p className="form-input-error">{commentError}</p> */}
                                        </div>
                                        <div className="col-lg-4 ">
                                        <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label>
                                        <select className="form-select" id="status" onChange={(e) => setStatus(e.target.value)}>
                                            <option selected style={{ display: "none" }}  >--Select--</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                       <p className="form-input-error">{StatusError}</p> 
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="Vehicle" className="col-form-label">Vehicle</label>
                                            <input type="text" className="form-control" id="Vehicle" onChange={(e) => setVehicle(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="Stock" className="col-form-label">Stock {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Stock" onChange={(e) => setStock(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="Color" className="col-form-label">Color {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Color" onChange={(e) => setColor(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="Source" className="col-form-label">Source {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Source" onChange={(e) => setSource(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 ">
                                            <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                            <select className="form-select" id="customerName" onChange={handleCompany} >
                                                <option style={{ display: "none" }}>Select CompanyName</option>
                                                {CompanyName.length > 0 &&
                                                    <>
                                                        {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{CompanyNameError}</p>
                                        </div>
                                        <div className="col-lg-4">
                                                <label htmlFor="customerName" className="col-form-label">Operator Name </label>
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                    id="Sale"
                                                    onChange={handleOperatorId}

                                                >
                                                    <option style={{ display: "none" }}>select OperatorName</option>
                                                    {OperatorName?.length > 0 && (
                                                        <>
                                                            {OperatorName.map((OperatorNameID) => (
                                                                <option
                                                                    key={OperatorNameID.operator_id}
                                                                    value={OperatorNameID.operator_id}
                                                                >
                                                                    {OperatorNameID.operatorname}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="customerName" className="col-form-label">List Name {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                            {/* <p className="form-input-error">{ListNameError}</p> */}
                                        </div>
                                    </div>
                                </form>
                                <div className="modal-footer">
                                    <button type="button" className="btn form-submit-btn" onClick={handleAdd} disabled = {submit}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};
export default CustomerAdd;